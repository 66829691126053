export const projectGridsConfig = [
  {
    projectId: 1,
    name: "Rainbow Villa 1",
    backgroundImagePath: "assets/images/projects/1/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/1",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/1/1.jpg",
      "assets/images/projects/1/2.jpg",
      "assets/images/projects/1/3.jpg",
      "assets/images/projects/1/4.jpg",
    ],
  },
  {
    projectId: 2,
    name: "Rainbow Villa 2",
    backgroundImagePath: "assets/images/projects/2/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/2",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/2/1.jpg",
      "assets/images/projects/2/2.jpg",
      "assets/images/projects/2/3.jpg",
      "assets/images/projects/2/4.jpg",
      "assets/images/projects/2/5.jpg",
    ],
  },
  {
    projectId: 3,
    name: "Rainbow Villa 3",
    backgroundImagePath: "assets/images/projects/3/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/3",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: ["assets/images/projects/3/1.jpg"],
  },
  {
    projectId: 4,
    name: "Rainbow Villa 4",
    backgroundImagePath: "assets/images/projects/4/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/4",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/4/1.jpg",
      "assets/images/projects/4/2.jpg",
      "assets/images/projects/4/3.jpg",
      "assets/images/projects/4/4.jpg",
      "assets/images/projects/4/5.jpg",
    ],
  },
  {
    projectId: 5,
    name: "Rainbow Villa 5",
    backgroundImagePath: "assets/images/projects/5/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/5",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/5/1.jpg",
      "assets/images/projects/5/2.jpg",
      "assets/images/projects/5/3.jpg",
    ],
  },
  {
    projectId: 6,
    name: "Rainbow Villa 6",
    backgroundImagePath: "assets/images/projects/6/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/6",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/6/1.jpg",
      "assets/images/projects/6/2.jpg",
      "assets/images/projects/6/3.jpg",
      "assets/images/projects/6/4.jpg",
      "assets/images/projects/6/5.jpg",
    ],
  },
  {
    projectId: 7,
    name: "Rainbow Villa 7",
    backgroundImagePath: "assets/images/projects/7/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/7",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/7/1.jpg",
      "assets/images/projects/7/2.jpg",
      "assets/images/projects/7/3.jpg",
      "assets/images/projects/7/4.jpg",
      "assets/images/projects/7/5.jpg",
      "assets/images/projects/7/6.jpg",
    ],
  },
  {
    projectId: 8,
    name: "Rainbow Villa 8",
    backgroundImagePath: "assets/images/projects/8/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/8",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/8/1.jpg",
      "assets/images/projects/8/2.jpg",
      "assets/images/projects/8/3.jpg",
      "assets/images/projects/8/4.jpg",
      "assets/images/projects/8/5.jpg",
      "assets/images/projects/8/6.jpg",
    ],
  },
  {
    projectId: 9,
    name: "Rainbow Villa 9",
    backgroundImagePath: "assets/images/projects/9/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/9",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/9/1.jpg",
      "assets/images/projects/9/2.jpg",
    ],
  },
  {
    projectId: 10,
    name: "Rainbow Villa 10 & 11",
    backgroundImagePath: "assets/images/projects/10/1.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/10",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/10/1.jpg",
      "assets/images/projects/10/2.jpg",
      "assets/images/projects/10/3.jpg",
      "assets/images/projects/10/4.jpg",
      "assets/images/projects/10/5.jpg",
      "assets/images/projects/10/6.jpg",
      "assets/images/projects/10/7.jpg",
      "assets/images/projects/10/8.jpg",
      "assets/images/projects/10/9.jpg",
      "assets/images/projects/10/10.jpg",
      "assets/images/projects/10/11.jpg",
      "assets/images/projects/10/12.jpg",
      "assets/images/projects/10/13.jpg",
      "assets/images/projects/10/14.jpg",
      "assets/images/projects/10/15.jpg",
      "assets/images/projects/10/16.jpg",
      "assets/images/projects/10/17.jpg",
      "assets/images/projects/10/18.jpg",
      "assets/images/projects/10/19.jpg",
      "assets/images/projects/10/20.jpg",
      "assets/images/projects/10/21.jpg",
    ],
  },
  {
    projectId: 11,
    name: "Rainbow Villa 12",
    backgroundImagePath: "assets/images/projects/12/13.jpeg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/11",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/12/1.jpeg",
      "assets/images/projects/12/2.jpeg",
      "assets/images/projects/12/3.jpeg",
      "assets/images/projects/12/4.jpeg",
      "assets/images/projects/12/5.jpeg",
      "assets/images/projects/12/6.jpeg",
      "assets/images/projects/12/7.jpeg",
      "assets/images/projects/12/8.jpeg",
      "assets/images/projects/12/9.jpeg",
      "assets/images/projects/12/10.jpeg",
      "assets/images/projects/12/11.jpeg",
      "assets/images/projects/12/12.jpeg",
      "assets/images/projects/12/13.jpeg",
    ],
  },
  {
    projectId: 12,
    name: "Rainbow Villa 13",
    backgroundImagePath: "assets/images/projects/13/1.jpeg",
    projectShortInfo:
      "Rainbow villa 13 ready to move in 3BHK Duplex in prime location, Plot area: 1432 sq.ft., Slab Area: 1749 sq.ft.",
    projectAddress: "Om Nagar, Koradi Road, Nagpur",
    inverted: false,
    projectLink: "/projects/12",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: ["assets/images/projects/13/1.jpeg"],
  },
  {
    projectId: 13,
    name: "Rainbow Dream Appartment",
    backgroundImagePath: "assets/images/projects/14/6.jpg",
    projectShortInfo: "",
    projectAddress: "",
    inverted: false,
    projectLink: "/projects/13",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: [
      "assets/images/projects/14/1.jpg",
      "assets/images/projects/14/2.jpg",
      "assets/images/projects/14/3.jpg",
      "assets/images/projects/14/4.jpg",
      "assets/images/projects/14/5.jpg",
      "assets/images/projects/14/6.jpg",
      "assets/images/projects/14/7.jpg",
      "assets/images/projects/14/8.jpg",
      "assets/images/projects/14/9.jpg",
      "assets/images/projects/14/10.jpg",
      "assets/images/projects/14/11.jpg",
      "assets/images/projects/14/12.jpg",
      "assets/images/projects/14/13.jpg",
      "assets/images/projects/14/14.jpg",
    ],
  },
  {
    projectId: 14,
    name: "Rainbow Villa 14",
    backgroundImagePath: "assets/images/projects/15/1.jpg",
    projectShortInfo: "",
    projectAddress:
      "Natraj Housing Society, Natraj Nagar, Gorewada, Nagpur, Maharashtra 441501",
    inverted: true,
    projectLink: "/projects/14",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8333291863064!2d79.05115201493557!3d21.198778985906422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1050e149d6b%3A0x6a1471cfc449d68b!2sRainbow%20Villa%2014!5e0!3m2!1sen!2sin!4v1597586124327!5m2!1sen!2sin",
    projectImages: [
      "assets/images/projects/15/1.jpg",
      "assets/images/projects/15/2.jpg",
      "assets/images/projects/15/3.jpg",
      "assets/images/projects/15/4.jpg",
      "assets/images/projects/15/5.jpg",
      "assets/images/projects/15/6.jpg",
      "assets/images/projects/15/7.jpg",
      "assets/images/projects/15/8.jpg",
      "assets/images/projects/15/9.jpg",
      "assets/images/projects/15/10.jpg",
      "assets/images/projects/15/11.jpg",
      "assets/images/projects/15/12.jpg",
    ],
    projectVideos: ["assets/images/projects/15/V13.mp4"],
  },
  {
    projectId: 15,
    name: "Rainbow Villa 15",
    backgroundImagePath: "assets/images/projects/r15/1.jpg",
    projectShortInfo: "",
    projectAddress: "75, Natraj Society,  Gorewada, Nagpur 441501",
    inverted: true,
    projectLink: "/projects/15",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3719.849897363019!2d79.0521267!3d21.198121!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1d81292a965%3A0x43aaf456603a27c6!2sRainbow%20Villa%2015!5e0!3m2!1sen!2sin!4v1641661009386!5m2!1sen!2sin",
    projectImages: [
      "assets/images/projects/r15/1.jpg",
      "assets/images/projects/r15/2.jpg",
    ],
    projectVideos: ["assets/images/projects/r15/V1.mp4"],
  },
  {
    projectId: 16,
    name: "Rainbow Villa 16",
    backgroundImagePath: "assets/images/projects/16/1.jpeg",
    projectShortInfo: "",
    projectAddress:
      "Rainbow Villa -16, Dabha, Vayusena Nagar, Nagpur, Maharashtra 440023",
    inverted: true,
    projectLink: "/projects/16",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29763.15932722715!2d79.03060872531815!3d21.17646534818458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb9b847ae531%3A0x7af14d703df791cb!2sRainbow%20Villa%20-16!5e0!3m2!1sen!2sin!4v1597583691957!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/16/1.jpeg"],
    projectVideos: ["assets/images/projects/16/V1.mp4"],
  },
  {
    projectId: 17,
    name: "Rainbow Villa 17",
    backgroundImagePath: "assets/images/projects/17/25.jpeg",
    projectShortInfo: "",
    projectAddress:
      "Bhatt Nagar, Shakti Vihar, Shambhu Nagar, Nagpur, Maharashtra 440026",
    inverted: true,
    projectLink: "/projects/17",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8951363751708!2d79.08036711493544!3d21.19632428590785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c153b8562029%3A0x62043c6e17ab545e!2sRainbow%20Villa%20-%2017!5e0!3m2!1sen!2sin!4v1599979305162!5m2!1sen!2sin",
    projectImages: [
      "assets/images/projects/17/25.jpeg",
      "assets/images/projects/17/1.jpg",
      "assets/images/projects/17/2.jpg",
      "assets/images/projects/17/3.jpg",
      "assets/images/projects/17/4.jpg",
      "assets/images/projects/17/5.jpg",
      "assets/images/projects/17/6.jpg",
      "assets/images/projects/17/7.jpg",
      "assets/images/projects/17/8.jpg",
      "assets/images/projects/17/9.jpg",
      "assets/images/projects/17/10.jpg",
      "assets/images/projects/17/11.jpg",
      "assets/images/projects/17/12.jpg",
      "assets/images/projects/17/13.jpg",
      "assets/images/projects/17/14.jpg",
      "assets/images/projects/17/15.jpg",
      "assets/images/projects/17/16.jpg",
      "assets/images/projects/17/17.jpg",
      "assets/images/projects/17/18.jpg",
      "assets/images/projects/17/19.jpg",
      "assets/images/projects/17/20.jpg",
      "assets/images/projects/17/21.jpg",
      "assets/images/projects/17/22.jpg",
      "assets/images/projects/17/23.jpg",
      "assets/images/projects/17/24.jpg",
    ],
    projectVideos: ["assets/images/projects/17/V1.mp4"],
  },
  {
    projectId: 18,
    name: "Rainbow Villa 18",
    backgroundImagePath: "assets/images/projects/18/1.jpeg",
    projectShortInfo: "",
    projectAddress: "Narmada Colony, Nagpur, Maharashtra 440013",
    inverted: true,
    projectLink: "/projects/18",
    isSold: true,
    sellingStatus: "Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.3161710297463!2d79.03777281493514!3d21.17959548591689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1ec4a8824ed%3A0x336c52b8d5db2b14!2sRainbow%20Villa%2018!5e0!3m2!1sen!2sin!4v1641660574167!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/18/1.jpeg"],
  },
  {
    projectId: 19,
    name: "Rainbow Villa 19",
    backgroundImagePath: "assets/images/projects/19/1.jpg",
    projectShortInfo: "",
    projectAddress: "Om Nagar Koradi Road, Nagpur",
    inverted: false,
    projectLink: "/projects/19",
    isSold: false,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.7987023929804!2d79.08073781493552!3d21.200154085905734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1a7a55357f1%3A0x94dd4c9badccce74!2sRainbow%20Villa%2019!5e0!3m2!1sen!2sin!4v1641660523033!5m2!1sen!2sin",
    projectImages: [
      "assets/images/projects/19/1.jpg",
      "assets/images/projects/19/2.jpg",
      "assets/images/projects/19/3.jpg",
      "assets/images/projects/19/4.jpg",
    ],
    projectLinks: [{ name: "Plan", url: "assets/images/projects/19/plan.pdf" }],
  },
  {
    projectId: 20,
    name: "Rainbow Villa 20",
    backgroundImagePath: "assets/images/projects/20/1.jpg",
    projectShortInfo: "",
    projectAddress: "Dabha, Vayusena Nagar, Nagpur, Maharashtra 440023",
    inverted: true,
    projectLink: "/projects/20",
    isSold: false,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.6299963729143!2d79.01463611493499!3d21.16711818592354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4ebe9fac19f99%3A0x63c0076756abceb6!2sRainbow%20Villa%20-%2020!5e0!3m2!1sen!2sin!4v1641656137695!5m2!1sen!2sin",
    projectImages: [
      "assets/images/projects/20/1.jpg",
      "assets/images/projects/20/2.jpg",
    ],
    projectLinks: [{ name: "Plan", url: "assets/images/projects/20/plan.pdf" }],
  },
  {
    projectId: 21,
    name: "Rainbow Villa 21",
    backgroundImagePath: "assets/images/projects/21/1.jpg",
    projectShortInfo: "",
    projectAddress: "Virchakra colony,Katol Road, Nagpur",
    inverted: false,
    projectLink: "/projects/21",
    isSold: true,
    sellingStatus: "Completed & Sold Out",
    map: "",
    projectImages: ["assets/images/projects/21/1.jpg"],
    projectLinks: [],
  },
  {
    projectId: 22,
    name: "Rainbow Villa 22",
    backgroundImagePath: "assets/images/projects/22/1.jpeg",
    projectShortInfo: "3BHK Duplex Bungalow",
    projectAddress: "Gajanan Nagar, Godhni road, Zindagi takli, Nagpur",
    inverted: true,
    projectLink: "/projects/22",
    isSold: false,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.742986807903!2d79.06676601493554!3d21.20236648590467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1bd5240ac2d%3A0xa89dfa7e25f7a778!2sRainbow%20Villa%2022!5e0!3m2!1sen!2sin!4v1650778275086!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/22/1.jpeg"],
    projectLinks: [{ name: "Plan", url: "assets/images/projects/22/plan.pdf" }],
  },
  {
    projectId: 23,
    name: "Rainbow Villa 23 & 24",
    backgroundImagePath: "assets/images/projects/23/1.jpg",
    projectShortInfo: "Duplex Bungalow",
    projectAddress: "Om Nagar, Koradi road, Nagpur",
    inverted: false,
    projectLink: "/projects/23",
    isSold: false,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.7580995135754!2d79.0827018!3d21.2017664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c17eacb29e63%3A0x855e56cf4ec36c5c!2sRainbow%20Villa%2023%20%26%2024!5e0!3m2!1sen!2sin!4v1682228269280!5m2!1sen!2sin",
    projectImages: [
      "assets/images/projects/23/1.jpg",
      "assets/images/projects/23/2.jpg",
    ],
    projectVideos: ["assets/images/projects/23/V1.mp4"],
  },
  {
    projectId: 25,
    name: "Rainbow Villa 25 & 26",
    backgroundImagePath: "assets/images/projects/25/1.jpg",
    projectShortInfo: "Duplex Bungalow",
    projectAddress: "Dabha, Nagpur",
    inverted: true,
    projectLink: "/projects/25",
    isSold: false,
    sellingStatus: "Completed & Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.64988003995!2d79.00579619999999!3d21.1663274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4ebbe277510bb%3A0x62402a72ff568b15!2sRainbow%20Villa%2025%20%26%2026!5e0!3m2!1sen!2sin!4v1682231463983!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/25/1.jpg"],
    projectVideos: [
      "assets/images/projects/25/1.mp4",
      "assets/images/projects/25/2.mp4",
      "assets/images/projects/25/3.mp4",
      "assets/images/projects/25/4.mp4",
      "assets/images/projects/25/5.mp4",
      "assets/images/projects/25/6.mp4",
      "assets/images/projects/25/7.mp4",
      "assets/images/projects/25/8.mp4",
    ],
  },
  {
    projectId: 26,
    name: "Rainbow Villa 28",
    backgroundImagePath: "assets/images/projects/27/1.jpg",
    projectShortInfo: "Duplex Bungalow",
    projectAddress: "Dabha, Nagpur",
    inverted: true,
    projectLink: "/projects/26",
    isSold: false,
    sellingStatus: "Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.7800616258273!2d79.0096921!3d21.161149299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb52a9239031%3A0xfdd5152b882e73fb!2sRainbow%20Villa%2028%2C%2029!5e0!3m2!1sen!2sin!4v1724854170178!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/27/1.jpg",
      "assets/images/projects/27/3.jpg"
    ],
    projectVideos: [
      "assets/images/projects/27/1.mp4",
      "assets/images/projects/27/2.mp4",
      // "assets/images/projects/26/3.jpg",
      // "assets/images/projects/26/4.mp4",
      // "assets/images/projects/26/5.mp4",
      // "assets/images/projects/26/6.mp4",
      // "assets/images/projects/26/7.mp4",
      // "assets/images/projects/26/8.mp4",
    ],
  },
  {
    projectId: 27,
    name: "Rainbow Villa 29",
    backgroundImagePath: "assets/images/projects/27/1.jpg",
    projectShortInfo: "Duplex Bungalow",
    projectAddress: "Dabha, Nagpur",
    inverted: true,
    projectLink: "/projects/27",
    isSold: false,
    sellingStatus: "Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.7800616258273!2d79.0096921!3d21.161149299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb52a9239031%3A0xfdd5152b882e73fb!2sRainbow%20Villa%2028%2C%2029!5e0!3m2!1sen!2sin!4v1724854170178!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/27/1.jpg",
      "assets/images/projects/27/3.jpg"
    ],
    projectVideos: [
      "assets/images/projects/27/1.mp4",
      "assets/images/projects/27/2.mp4",
      // "assets/images/projects/26/3.jpg",
      // "assets/images/projects/26/4.mp4",
      // "assets/images/projects/26/5.mp4",
      // "assets/images/projects/26/6.mp4",
      // "assets/images/projects/26/7.mp4",
      // "assets/images/projects/26/8.mp4",
    ],
  },
  {
    projectId: 28,
    name: "Rainbow Villa 30",
    backgroundImagePath: "assets/images/projects/28/1.jpg",
    projectShortInfo: "Duplex Bungalow",
    projectAddress: "Dabha, Nagpur",
    inverted: true,
    projectLink: "/projects/28",
    isSold: false,
    sellingStatus: "Sold Out",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.809328876594!2d79.01266400000002!3d21.159985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb5d666d69bb%3A0x8d6b80320a269260!2sRainbow%20Villa%2030!5e0!3m2!1sen!2sin!4v1724855431172!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/28/1.jpg",
      "assets/images/projects/28/2.jpg",
      "assets/images/projects/28/3.jpg",
      "assets/images/projects/28/4.jpg",
      "assets/images/projects/28/5.jpg",
      "assets/images/projects/28/6.jpg",
      "assets/images/projects/28/7.jpg",
      "assets/images/projects/28/8.jpg",
      "assets/images/projects/28/9.jpg",
      "assets/images/projects/28/10.jpg",
      "assets/images/projects/28/11.jpg",
      "assets/images/projects/28/12.jpg",
      "assets/images/projects/28/13.jpg",
      "assets/images/projects/28/14.jpg",
      "assets/images/projects/28/15.jpg",
      "assets/images/projects/28/16.jpg",
      "assets/images/projects/28/17.jpg",
      "assets/images/projects/28/18.jpg"
    ],
    
    projectVideos: [
    //  "assets/images/projects/27/1.mp4",
    //   "assets/images/projects/2/2.mp4",
    //   // "assets/images/projects/26/3.jpg",
    //   // "assets/images/projects/26/4.mp4",
    //   // "assets/images/projects/26/5.mp4",
    //   // "assets/images/projects/26/6.mp4",
    //   // "assets/images/projects/26/7.mp4",
    //   // "assets/images/projects/26/8.mp4",
    ],
  
  },
  {
    projectId: 29,
    name: "Rainbow Villa 31",
    backgroundImagePath: "assets/images/projects/29/2.jpg",
    projectShortInfo: " Ready To Move, 4bhk Duplex Bungalow. Plot area 1454 Sq.ft. North East facing Slab Area 2072 Sq.ft.",
    projectAddress: "PVK, Dabha, Nagpur",
    inverted: true,
    projectLink: "/projects/29",
    isSold: false,
    sellingStatus: "Available For Sale",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.7877361639175!2d79.01236399999999!3d21.160843999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb0054a83e1b%3A0x315ca09effc8ecd1!2sRainbow%20Villa%2031!5e0!3m2!1sen!2sin!4v1725784186212!5m2!1sen!2sin",
    projectImages: ["assets/images/projects/29/2.jpg",
      "assets/images/projects/29/3.jpg",
      "assets/images/projects/29/2.jpg"
    ],
    
    projectVideos: [
   
    ],
  
  },
];

