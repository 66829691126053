import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class AppService {
    BASE_API_URL = "https://api-ra-prod-eus-mailer.azurewebsites.net/"
    constructor(private http: HttpClient) { }

    sendVisitorForm(formModel: any) {
        return this.http.post(this.BASE_API_URL + "EmailApi/SendEnquiry", formModel);
    }
}