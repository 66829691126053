import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-project-grid",
  templateUrl: "./project-grid.component.html",
  styleUrls: ["./project-grid.component.scss"]
})
export class ProjectGridComponent implements OnInit {
  @Input() projectGrids;
  @Input() gridClass;
  @Input() gridHeight;
  constructor() {}

  ngOnInit() {}
}
