import { Component, OnInit } from "@angular/core";
import { projectGridsConfig } from "./project-list.config";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent implements OnInit {
  projectGrids;
  constructor() {
    this.projectGrids = projectGridsConfig.sort(
      (a, b) => b.projectId - a.projectId
    );
  }

  ngOnInit() {}
}
