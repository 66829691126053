import { Component, OnInit } from "@angular/core";
import { projectGridsConfig } from "../project/project-list.config";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  topProject;
  constructor() {
    this.topProject = projectGridsConfig.sort(
      (a, b) => b.projectId - a.projectId
    )[0];
  }

  ngOnInit() {}
}
