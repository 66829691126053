import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  recaptchaKey = "6Lf6KroZAAAAAHcn3ChADxWPGL7jop-VSIMLQaub";
  contactForm: FormGroup;
  submitDisabled: boolean = false;

  constructor(private fb: FormBuilder,
    private appService: AppService) {

    this.contactForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', Validators.email],
      contactNo: ['', [Validators.required, Validators.maxLength(12), Validators.minLength(6), Validators.pattern(/^\d+$/)]],
      comments: ['', Validators.maxLength(500)],
      captcha: ['']
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.submitDisabled = true;
      this.appService.sendVisitorForm(this.contactForm.value)
        .subscribe((data) => {
          this.submitDisabled = false;
        }, error => {
          this.submitDisabled = false;
        });
    }
  }

  resolved(captchaResponse: string) {
    this.contactForm.controls.captcha.setValue(captchaResponse);
  }

}
