import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { projectGridsConfig } from "../project-list.config";

@Component({
  selector: "app-single-project",
  templateUrl: "./single-project.component.html",
  styleUrls: ["./single-project.component.scss"],
})
export class SingleProjectComponent {
  constructor(private route: ActivatedRoute) {}
  projectId: number;
  project;
  totalProjects: number = 0;
  ngOnInit() {
    this.projectId = this.route.snapshot.params["id"];
    this.project = projectGridsConfig.find(
      (a) => a.projectId == this.projectId
    );
    this.totalProjects = projectGridsConfig.length;
  }
}
