import { Component, OnInit } from "@angular/core";
import { projectGridsConfig } from "../project/project-list.config";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  projectGrids;
  constructor() {
    this.projectGrids = projectGridsConfig.sort(
      (a, b) => b.projectId - a.projectId
    ).slice(0,8);
  }

  ngOnInit() {}
}
