import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from './services/app.service';

export class AppModalContentComponent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private modalRef: NgbModalRef;
  @ViewChild('modalButton', { static: true }) modalButton: ElementRef<HTMLElement>;
  recaptchaKey = "6Lf6KroZAAAAAHcn3ChADxWPGL7jop-VSIMLQaub";
  contactForm: FormGroup;
  submitDisabled: boolean = false;

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private appService: AppService) {
    config.backdrop = 'static';
    config.keyboard = false;

    this.contactForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', Validators.email],
      contactNo: ['', [Validators.required, Validators.maxLength(12), Validators.minLength(6), Validators.pattern(/^\d+$/)]],
      comments: ['', Validators.maxLength(500)],
      captcha: ['']
    });
  }

  ngAfterViewInit() {
    let el: HTMLElement = this.modalButton.nativeElement;
    el.click();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  openVisitorModal(content) {
    this.modalRef = this.modalService.open(content, {
      centered: true
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.submitDisabled = true;
      this.appService.sendVisitorForm(this.contactForm.value)
        .subscribe((data) => {
          this.submitDisabled = false;
          this.modalRef.close();
        }, error => {
          this.submitDisabled = false;
        });
    }
  }

  resolved(captchaResponse: string) {
    this.contactForm.controls.captcha.setValue(captchaResponse);
  }
}
